<template>
  <div>
    <vx-card class="pt-5 pb-5">
      <div v-if="!isFetching" class="flex content-center">
          <stock-image v-if="stock.imageStock !== null" fullImg :data="stock.imageStock" />
        <div class="ml-4">
          <div class="flex flex-col p-2">
            <h1>{{ stock.title }}</h1>
            <div v-if="stock.type !== 'materiel'" class="flex mt-4">
              <div class="mr-4">
                <h2 class="text-lg">{{$t('stock.restock.title.actualStock')}} {{ stock.stock }}</h2>
              </div>
              <div class="mr-4">
                <h2 class="text-lg">{{$t('stock.restock.title.variation')}}
                  <span :class="stockEvent.variation > 0 ? 'text-success' : 'text-danger'"> 
                    {{ stockEvent.variation }}
                  </span>
                </h2>
              </div>
              <div class="">
                <h2 class="text-lg">{{$t('stock.restock.title.newStock')}} {{ newStock }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <ValidationObserver v-slot="{ invalid, handleSubmit }">
        <form @submit.prevent="handleSubmit(submitRestock)">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
            <ValidationProvider v-if="stock.type !== 'materiel'" :name="$t('stock.form.restock.variation')" rules="required" v-slot="{ errors }">
              <p class="mt-4"> {{$t('stock.form.restock.variation')}}</p>
              <vs-input type="number" class="w-full mt-4" v-model="stockEvent.variation" name="variation" />
              <span class="error-class">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider v-else :name="$t('stock.form.restock.variation')" rules="required" v-slot="{ errors }">
              <vs-button button="submit" :disabled="stock.stock < 1" color="danger" @click="isMaterielDestock=true" class="ml-auto mt-2">{{ $t('stock.form.destock.input') }}</vs-button>
              <vs-button button="submit" :disabled="stock.stock > 0"  color="success" @click="isMaterielRestock=true" class="ml-4 mt-2">{{ $t('stock.form.restock.input') }}</vs-button>
              <span class="error-class">{{ errors[0] }}</span>
            </ValidationProvider>
              <!-- raison -->
              <ValidationProvider :name="$t('stock.form.restock.raison')" rules="required" v-slot="{ errors }">
                <vs-input class="w-full mt-4" :label="$t('stock.form.restock.raison')" v-model="stockEvent.raison" name="raison" />
                <span class="error-class">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="mt-8 flex flex-wrap items-center justify-start">
            
          </div>
          <div class="mt-8 flex flex-wrap items-center justify-space-between">
            <vs-button @click="goStockList(stock.type)" class="mt-2" color="grey" >{{ $t('stock.form.input.back') }}</vs-button>
            <vs-button v-if="stock.type !== 'materiel'" button="submit" class="ml-auto mt-2" :disabled="invalid">{{ $t('stock.form.input.submit') }}</vs-button>
          </div>
        </form>
      </ValidationObserver>
    </vx-card>
  </div>
</template>

<script>
// Store Module
import moduleStockMixin from '@/store/stock/moduleStockMixin'
import StockImage from '@/views/apps/stock/stock-list/cell-renderer/CellRendererStockImage'

export default {
  components: {
    StockImage
  },
  mixins: [moduleStockMixin],
  data () {
    return {
      stock: {},
      stockEvent: { variation:1, stock: `/stock/stocks/${this.$route.params.id}` },
      isMaterielDestock: false,
      isMaterielRestock: false,
      isFetching: false,
      sliderKey: 0
    }
  },
  created () {
    this.fetchStock()
  },
  computed: {
    newStock () {
      return (parseInt(this.stock.stock) + parseInt(this.stockEvent.variation))
    }
  },
  methods: {
    fetchStock () {
      this.stock.id = this.$route.params.id
      this.isFetching = true
      this.$store.dispatch('stock/fetchStock', this.stock)
        .then(response => {
          this.stock = {...response.data}
          this.isFetching = false
          this.sliderKey++
        })
    },
    submitRestock () {
      if (this.isMaterielDestock) {
        this.materielDestock()
        return
      } else if (this.isMaterielRestock) {
        this.materielRestock()
        return
      } else {
        this.stockEvent.variation = parseInt(this.stockEvent.variation)
      }
      this.$store.dispatch('stock/addStockEvents', this.stockEvent)
        .then(() => {
          this.$router.push({name: 'stock-list-gadget', params: { type: this.stock.type } })
        })
    },
    materielDestock () {
      this.stockEvent.variation = -1
      this.$vs.notify({
        color: 'success',
        title: this.$t('stock.form.destock.success.notify.title'),
        text: this.$t('stock.form.destock.success.notify.text', { name: this.stock.title })
      })
      this.$store.dispatch('stock/addStockEvents', this.stockEvent)
        .then(() => {
          this.$router.push({name: 'stock-list-materiel' })
        })
    },
    materielRestock () {
      this.stockEvent.variation = 1
      this.$vs.notify({
        color: 'success',
        title: this.$t('stock.form.restock.success.notify.title'),
        text: this.$t('stock.form.restock.success.notify.text', { name: this.stock.title })
      })
      this.$store.dispatch('stock/addStockEvents', this.stockEvent)
        .then(() => {
          this.$router.push({name: 'stock-list-materiel' })
        })
    },
    goStockList (typeStock) {
      if (typeStock === 'materiel') {
        this.$router.push({name: 'stock-list-materiel'})
      } else {
        this.$router.push({name: 'stock-list-gadget', params: { type: typeStock }})
      }
    }
  }
}
</script>

<style scoped>

</style>
